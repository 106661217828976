import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import { Col, Row } from 'reactstrap';
import useAxiosPrivate from '../hooks/useAxiosPrivate';
import useFilter from '../hooks/useFilter';

export default function TrainingCodes() {
    const axiosPrivate = useAxiosPrivate();
    const { filter } = useFilter();
    const [loading, setLoading] = useState(true);
    const [selectAll, setSelectAll] = useState(true);
    const [includeNullTrainingCodes, setIncludeNullTrainingCodes] = useState(true);
    const [selectedTrainingCodes, setSelectedTrainingCodes] = useState([]);
    const [customSelectedTrainingCodes, setCustomSelectedTrainingCodes] = useState([]);
    const [trainingCodes, setTrainingCodes] = useState([]);
    const [selectedTrainingCodeTypes, setSelectedTrainingCodeTypes] = useState([]);
    const [customSelectedTrainingCodeTypes, setCustomSelectedTrainingCodeTypes] = useState([]);
    const [trainingCodeTypes, setTrainingCodeTypes] = useState([]);

    useEffect(() => {
        const getTrainingCodes = async () => {
            if(filter?.includeNullTrainingCodes == false)
            {
                setIncludeNullTrainingCodes(filter.includeNullTrainingCodes)
            }
            if(filter?.formTrainingCodes)
            {
                setTrainingCodes(filter.formTrainingCodes);
                if(filter?.selectAllTrainingCodes){
                    setSelectAll(true); 
                    setSelectedTrainingCodes(filter.formTrainingCodes);               
                }
                else
                {
                    setSelectAll(false);
                    var selected = filter.formTrainingCodes.filter(trainingCode => filter.trainingCodes.includes(trainingCode.label))
                    setCustomSelectedTrainingCodes(selected);
                    setSelectedTrainingCodes(selected);
                }                
            }
            else
            {
                try{
                    await axiosPrivate.get('/api/core/trainingcodes')
                        .then(response =>{
                            var distinctTrainingCodes = [...new Set(response.data.map(code => code.code))];
                            let selectId = 1;
                            var trainingCodes = distinctTrainingCodes.map(code => ({value: selectId++, label: code}));
                            setTrainingCodes(trainingCodes);
                            setSelectedTrainingCodes(trainingCodes);
                        });
                }
                catch(err){
                    console.log(err);
                }
            }     
        };  
        const getTrainingCodeTypes = async () => {
            if(filter?.formTrainingCodeTypes)
            {
                setTrainingCodeTypes(filter.formTrainingCodeTypes);
                if(filter?.selectAllTrainingCodes){
                    setSelectAll(true); 
                    setSelectedTrainingCodeTypes(filter.formTrainingCodeTypes);               
                }
                else
                {
                    setSelectAll(false);
                    var selected = filter.formTrainingCodeTypes.filter(trainingCodeType => filter.trainingCodeTypes.includes(trainingCodeType.label))
                    setCustomSelectedTrainingCodeTypes(selected);
                    setSelectedTrainingCodeTypes(selected);
                }                
            }
            else
            {
                try{
                    await axiosPrivate.get('/api/core/trainingcodetypes')
                        .then(response =>{
                            var distinctTrainingCodeTypes = [...new Set(response.data.map(type => type.type))];
                            let selectId = 1;
                            var trainingCodeTypes = distinctTrainingCodeTypes.map(type => ({value: selectId++, label: `(${type})`}));
                            setTrainingCodeTypes(trainingCodeTypes);
                            setSelectedTrainingCodeTypes(trainingCodeTypes);
                        });
                }
                catch(err){
                    console.log(err);
                }
            }            
        };              
        Promise.all([getTrainingCodes(), getTrainingCodeTypes()]).then(function(){
            setLoading(false);
        });
    }, []);

    const sortOptions = (options) => {
        return options.sort((a, b) => a.label.localeCompare(b.label));
    }

    const onChange = (value) => {
        setSelectAll(value);
        if(value === true){
            setSelectedTrainingCodes([]);
            setSelectedTrainingCodeTypes([]);            
        }
        else{
            setSelectedTrainingCodes(customSelectedTrainingCodes);
            setSelectedTrainingCodeTypes(customSelectedTrainingCodeTypes);            
        }
    };

    const onTrainingCodesCheckboxChange = (e) => {
        setIncludeNullTrainingCodes(e.target.checked);
    };  

    const onSelectedTrainingCodesChange = (value) => {
        setCustomSelectedTrainingCodes(value);
        setSelectedTrainingCodes(value);
    };

    const onSelectedTrainingCodeTypesChange = (value) => {
        setCustomSelectedTrainingCodeTypes(value);
        setSelectedTrainingCodeTypes(value);
    };    

    return ({
        loading, selectAll, includeNullTrainingCodes, selectedTrainingCodes, trainingCodes, selectedTrainingCodeTypes, trainingCodeTypes, 
        render:
        <Row>
            <Col xs="6">
                <h5>Training Codes</h5>
                <div>
                    <input type='checkbox' onChange={onTrainingCodesCheckboxChange} checked={includeNullTrainingCodes}/> Include Records With No Training Codes
                </div>
            </Col>
            <Col xs="6">
                <div>
                    <input className='radio-margin' type='radio' name='trainingCodesRadio' onChange={() => onChange(true)} checked={selectAll}/> Select All
                    <input className='radio-margin' type='radio' name='trainingCodesRadio' onChange={() => onChange(false)} checked={!selectAll}/> Custom Selection
                </div>
                <div>
                    {!selectAll ? 
                    <div>
                        <label>Training Code Sets</label>
                        <Select isMulti value={selectedTrainingCodeTypes} options={sortOptions(trainingCodeTypes)} onChange={onSelectedTrainingCodeTypesChange} />
                        <label>Training Codes</label>
                        <Select isMulti value={selectedTrainingCodes} options={sortOptions(trainingCodes)} onChange={onSelectedTrainingCodesChange} />                        
                    </div>
                    : null}
                </div>
            </Col>
        </Row>
    });
}